// plugins/gtm.client.ts

import { defineNuxtPlugin } from '#app';
import VueGtm from '@gtm-support/vue-gtm';

export default defineNuxtPlugin((nuxtApp) => {
  const { public: { gtm } } = useRuntimeConfig();
  nuxtApp.vueApp.use(VueGtm, {
    id: gtm.id, // Remplacez par votre ID GTM
    vueRouter: nuxtApp.$router,
    enabled: process.env.NODE_ENV === 'production',
    debug: process.env.NODE_ENV !== 'production',
    loadScript: true,
    trackOnNextTick: false,
  });
});
