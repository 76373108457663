import lang from "quasar/lang/en-US.mjs"
import iconSet from "quasar/icon-set/material-icons.mjs"



export const componentsWithDefaults = {  }

export const appConfigKey = "nuxtQuasar"

export const quasarNuxtConfig = {
  lang,
  iconSet,
  components: {"defaults":{}},
  plugins: {},
  
}