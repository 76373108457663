import validate from "/src/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/src/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "init-acquisition": () => import("/src/middleware/init-acquisition.ts"),
  "merge-menu": () => import("/src/middleware/merge-menu.ts"),
  "next-cat": () => import("/src/middleware/next-cat.ts"),
  promo: () => import("/src/middleware/promo.ts"),
  you: () => import("/src/middleware/you.ts")
}