import { default as brandzbxwQ1ldQvMeta } from "/src/pages/funnel/eat-habits/dry/brand.vue?macro=true";
import { default as indexj1IbOjGIIYMeta } from "/src/pages/funnel/eat-habits/dry/index.vue?macro=true";
import { default as preference5RKyceULbFMeta } from "/src/pages/funnel/eat-habits/dry/preference.vue?macro=true";
import { default as indexs0LiSYzKTBMeta } from "/src/pages/funnel/eat-habits/index.vue?macro=true";
import { default as brandnySEm2dgeJMeta } from "/src/pages/funnel/eat-habits/wet/brand.vue?macro=true";
import { default as indexlwoeMyhBHMMeta } from "/src/pages/funnel/eat-habits/wet/index.vue?macro=true";
import { default as generateVcR66mvGZiMeta } from "/src/pages/funnel/generate.vue?macro=true";
import { default as indexyWdrJO66kGMeta } from "/src/pages/funnel/golden-rules/index.vue?macro=true";
import { default as reminderkccNWr1hTUMeta } from "/src/pages/funnel/golden-rules/reminder.vue?macro=true";
import { default as indexUjmtQNe2ZYMeta } from "/src/pages/funnel/health/allergies/index.vue?macro=true";
import { default as ingredientsmN5TDlvYp4Meta } from "/src/pages/funnel/health/allergies/ingredients.vue?macro=true";
import { default as indexeWH6SDT29QMeta } from "/src/pages/funnel/health/index.vue?macro=true";
import { default as confirmhI6zyA9Ye6Meta } from "/src/pages/funnel/health/sickness/confirm.vue?macro=true";
import { default as indexSRIFFDEY5XMeta } from "/src/pages/funnel/health/sickness/index.vue?macro=true";
import { default as confirmjgxi9You8VMeta } from "/src/pages/funnel/many/confirm.vue?macro=true";
import { default as indexaQipnaFfu4Meta } from "/src/pages/funnel/many/index.vue?macro=true";
import { default as merge_45menuh0zLl5OacSMeta } from "/src/pages/funnel/merge-menu.vue?macro=true";
import { default as nameszXYNm3cIMMeta } from "/src/pages/funnel/name.vue?macro=true";
import { default as next_45catHpc0SaLwq6Meta } from "/src/pages/funnel/next-cat.vue?macro=true";
import { default as confirm5QcUPuKtymMeta } from "/src/pages/funnel/profile/age/confirm.vue?macro=true";
import { default as indexwedG4ffYVWMeta } from "/src/pages/funnel/profile/age/index.vue?macro=true";
import { default as breedtsZ89UF8kgMeta } from "/src/pages/funnel/profile/breed.vue?macro=true";
import { default as gender11tX6Gm8hqMeta } from "/src/pages/funnel/profile/gender.vue?macro=true";
import { default as indexUgWisVug7yMeta } from "/src/pages/funnel/profile/index.vue?macro=true";
import { default as neuteredRiSTwbOsrcMeta } from "/src/pages/funnel/profile/neutered.vue?macro=true";
import { default as resumeMLYMFTGc0MMeta } from "/src/pages/funnel/resume.vue?macro=true";
import { default as activityjy5GQg4hAYMeta } from "/src/pages/funnel/weight-analysis/activity.vue?macro=true";
import { default as confirmHodc8OPOuzMeta } from "/src/pages/funnel/weight-analysis/fatness/confirm.vue?macro=true";
import { default as advicesKUPjKh2vl3Meta } from "/src/pages/funnel/weight-analysis/fatness/help/advices.vue?macro=true";
import { default as index7n66eYDDvWMeta } from "/src/pages/funnel/weight-analysis/fatness/help/index.vue?macro=true";
import { default as index8peen58gGcMeta } from "/src/pages/funnel/weight-analysis/fatness/index.vue?macro=true";
import { default as indexoCEH1DHZnuMeta } from "/src/pages/funnel/weight-analysis/index.vue?macro=true";
import { default as weightwHjWRXe8vjMeta } from "/src/pages/funnel/weight-analysis/weight.vue?macro=true";
import { default as youWXfuxlmy14Meta } from "/src/pages/funnel/you.vue?macro=true";
export default [
  {
    name: brandzbxwQ1ldQvMeta?.name ?? "funnel-eat-habits-dry-brand",
    path: brandzbxwQ1ldQvMeta?.path ?? "/funnel/eat-habits/dry/brand",
    meta: brandzbxwQ1ldQvMeta || {},
    alias: brandzbxwQ1ldQvMeta?.alias || [],
    redirect: brandzbxwQ1ldQvMeta?.redirect,
    component: () => import("/src/pages/funnel/eat-habits/dry/brand.vue").then(m => m.default || m)
  },
  {
    name: indexj1IbOjGIIYMeta?.name ?? "funnel-eat-habits-dry",
    path: indexj1IbOjGIIYMeta?.path ?? "/funnel/eat-habits/dry",
    meta: indexj1IbOjGIIYMeta || {},
    alias: indexj1IbOjGIIYMeta?.alias || [],
    redirect: indexj1IbOjGIIYMeta?.redirect,
    component: () => import("/src/pages/funnel/eat-habits/dry/index.vue").then(m => m.default || m)
  },
  {
    name: preference5RKyceULbFMeta?.name ?? "funnel-eat-habits-dry-preference",
    path: preference5RKyceULbFMeta?.path ?? "/funnel/eat-habits/dry/preference",
    meta: preference5RKyceULbFMeta || {},
    alias: preference5RKyceULbFMeta?.alias || [],
    redirect: preference5RKyceULbFMeta?.redirect,
    component: () => import("/src/pages/funnel/eat-habits/dry/preference.vue").then(m => m.default || m)
  },
  {
    name: indexs0LiSYzKTBMeta?.name ?? "funnel-eat-habits",
    path: indexs0LiSYzKTBMeta?.path ?? "/funnel/eat-habits",
    meta: indexs0LiSYzKTBMeta || {},
    alias: indexs0LiSYzKTBMeta?.alias || [],
    redirect: indexs0LiSYzKTBMeta?.redirect,
    component: () => import("/src/pages/funnel/eat-habits/index.vue").then(m => m.default || m)
  },
  {
    name: brandnySEm2dgeJMeta?.name ?? "funnel-eat-habits-wet-brand",
    path: brandnySEm2dgeJMeta?.path ?? "/funnel/eat-habits/wet/brand",
    meta: brandnySEm2dgeJMeta || {},
    alias: brandnySEm2dgeJMeta?.alias || [],
    redirect: brandnySEm2dgeJMeta?.redirect,
    component: () => import("/src/pages/funnel/eat-habits/wet/brand.vue").then(m => m.default || m)
  },
  {
    name: indexlwoeMyhBHMMeta?.name ?? "funnel-eat-habits-wet",
    path: indexlwoeMyhBHMMeta?.path ?? "/funnel/eat-habits/wet",
    meta: indexlwoeMyhBHMMeta || {},
    alias: indexlwoeMyhBHMMeta?.alias || [],
    redirect: indexlwoeMyhBHMMeta?.redirect,
    component: () => import("/src/pages/funnel/eat-habits/wet/index.vue").then(m => m.default || m)
  },
  {
    name: generateVcR66mvGZiMeta?.name ?? "funnel-generate",
    path: generateVcR66mvGZiMeta?.path ?? "/funnel/generate",
    meta: generateVcR66mvGZiMeta || {},
    alias: generateVcR66mvGZiMeta?.alias || [],
    redirect: generateVcR66mvGZiMeta?.redirect,
    component: () => import("/src/pages/funnel/generate.vue").then(m => m.default || m)
  },
  {
    name: indexyWdrJO66kGMeta?.name ?? "funnel-golden-rules",
    path: indexyWdrJO66kGMeta?.path ?? "/funnel/golden-rules",
    meta: indexyWdrJO66kGMeta || {},
    alias: indexyWdrJO66kGMeta?.alias || [],
    redirect: indexyWdrJO66kGMeta?.redirect,
    component: () => import("/src/pages/funnel/golden-rules/index.vue").then(m => m.default || m)
  },
  {
    name: reminderkccNWr1hTUMeta?.name ?? "funnel-golden-rules-reminder",
    path: reminderkccNWr1hTUMeta?.path ?? "/funnel/golden-rules/reminder",
    meta: reminderkccNWr1hTUMeta || {},
    alias: reminderkccNWr1hTUMeta?.alias || [],
    redirect: reminderkccNWr1hTUMeta?.redirect,
    component: () => import("/src/pages/funnel/golden-rules/reminder.vue").then(m => m.default || m)
  },
  {
    name: indexUjmtQNe2ZYMeta?.name ?? "funnel-health-allergies",
    path: indexUjmtQNe2ZYMeta?.path ?? "/funnel/health/allergies",
    meta: indexUjmtQNe2ZYMeta || {},
    alias: indexUjmtQNe2ZYMeta?.alias || [],
    redirect: indexUjmtQNe2ZYMeta?.redirect,
    component: () => import("/src/pages/funnel/health/allergies/index.vue").then(m => m.default || m)
  },
  {
    name: ingredientsmN5TDlvYp4Meta?.name ?? "funnel-health-allergies-ingredients",
    path: ingredientsmN5TDlvYp4Meta?.path ?? "/funnel/health/allergies/ingredients",
    meta: ingredientsmN5TDlvYp4Meta || {},
    alias: ingredientsmN5TDlvYp4Meta?.alias || [],
    redirect: ingredientsmN5TDlvYp4Meta?.redirect,
    component: () => import("/src/pages/funnel/health/allergies/ingredients.vue").then(m => m.default || m)
  },
  {
    name: indexeWH6SDT29QMeta?.name ?? "funnel-health",
    path: indexeWH6SDT29QMeta?.path ?? "/funnel/health",
    meta: indexeWH6SDT29QMeta || {},
    alias: indexeWH6SDT29QMeta?.alias || [],
    redirect: indexeWH6SDT29QMeta?.redirect,
    component: () => import("/src/pages/funnel/health/index.vue").then(m => m.default || m)
  },
  {
    name: confirmhI6zyA9Ye6Meta?.name ?? "funnel-health-sickness-confirm",
    path: confirmhI6zyA9Ye6Meta?.path ?? "/funnel/health/sickness/confirm",
    meta: confirmhI6zyA9Ye6Meta || {},
    alias: confirmhI6zyA9Ye6Meta?.alias || [],
    redirect: confirmhI6zyA9Ye6Meta?.redirect,
    component: () => import("/src/pages/funnel/health/sickness/confirm.vue").then(m => m.default || m)
  },
  {
    name: indexSRIFFDEY5XMeta?.name ?? "funnel-health-sickness",
    path: indexSRIFFDEY5XMeta?.path ?? "/funnel/health/sickness",
    meta: indexSRIFFDEY5XMeta || {},
    alias: indexSRIFFDEY5XMeta?.alias || [],
    redirect: indexSRIFFDEY5XMeta?.redirect,
    component: () => import("/src/pages/funnel/health/sickness/index.vue").then(m => m.default || m)
  },
  {
    name: confirmjgxi9You8VMeta?.name ?? "funnel-many-confirm",
    path: confirmjgxi9You8VMeta?.path ?? "/funnel/many/confirm",
    meta: confirmjgxi9You8VMeta || {},
    alias: confirmjgxi9You8VMeta?.alias || [],
    redirect: confirmjgxi9You8VMeta?.redirect,
    component: () => import("/src/pages/funnel/many/confirm.vue").then(m => m.default || m)
  },
  {
    name: indexaQipnaFfu4Meta?.name ?? "funnel-many",
    path: indexaQipnaFfu4Meta?.path ?? "/funnel/many",
    meta: indexaQipnaFfu4Meta || {},
    alias: indexaQipnaFfu4Meta?.alias || [],
    redirect: indexaQipnaFfu4Meta?.redirect,
    component: () => import("/src/pages/funnel/many/index.vue").then(m => m.default || m)
  },
  {
    name: merge_45menuh0zLl5OacSMeta?.name ?? "funnel-merge-menu",
    path: merge_45menuh0zLl5OacSMeta?.path ?? "/funnel/merge-menu",
    meta: merge_45menuh0zLl5OacSMeta || {},
    alias: merge_45menuh0zLl5OacSMeta?.alias || [],
    redirect: merge_45menuh0zLl5OacSMeta?.redirect,
    component: () => import("/src/pages/funnel/merge-menu.vue").then(m => m.default || m)
  },
  {
    name: nameszXYNm3cIMMeta?.name ?? "funnel-name",
    path: nameszXYNm3cIMMeta?.path ?? "/funnel/name",
    meta: nameszXYNm3cIMMeta || {},
    alias: nameszXYNm3cIMMeta?.alias || [],
    redirect: nameszXYNm3cIMMeta?.redirect,
    component: () => import("/src/pages/funnel/name.vue").then(m => m.default || m)
  },
  {
    name: next_45catHpc0SaLwq6Meta?.name ?? "funnel-next-cat",
    path: next_45catHpc0SaLwq6Meta?.path ?? "/funnel/next-cat",
    meta: next_45catHpc0SaLwq6Meta || {},
    alias: next_45catHpc0SaLwq6Meta?.alias || [],
    redirect: next_45catHpc0SaLwq6Meta?.redirect,
    component: () => import("/src/pages/funnel/next-cat.vue").then(m => m.default || m)
  },
  {
    name: confirm5QcUPuKtymMeta?.name ?? "funnel-profile-age-confirm",
    path: confirm5QcUPuKtymMeta?.path ?? "/funnel/profile/age/confirm",
    meta: confirm5QcUPuKtymMeta || {},
    alias: confirm5QcUPuKtymMeta?.alias || [],
    redirect: confirm5QcUPuKtymMeta?.redirect,
    component: () => import("/src/pages/funnel/profile/age/confirm.vue").then(m => m.default || m)
  },
  {
    name: indexwedG4ffYVWMeta?.name ?? "funnel-profile-age",
    path: indexwedG4ffYVWMeta?.path ?? "/funnel/profile/age",
    meta: indexwedG4ffYVWMeta || {},
    alias: indexwedG4ffYVWMeta?.alias || [],
    redirect: indexwedG4ffYVWMeta?.redirect,
    component: () => import("/src/pages/funnel/profile/age/index.vue").then(m => m.default || m)
  },
  {
    name: breedtsZ89UF8kgMeta?.name ?? "funnel-profile-breed",
    path: breedtsZ89UF8kgMeta?.path ?? "/funnel/profile/breed",
    meta: breedtsZ89UF8kgMeta || {},
    alias: breedtsZ89UF8kgMeta?.alias || [],
    redirect: breedtsZ89UF8kgMeta?.redirect,
    component: () => import("/src/pages/funnel/profile/breed.vue").then(m => m.default || m)
  },
  {
    name: gender11tX6Gm8hqMeta?.name ?? "funnel-profile-gender",
    path: gender11tX6Gm8hqMeta?.path ?? "/funnel/profile/gender",
    meta: gender11tX6Gm8hqMeta || {},
    alias: gender11tX6Gm8hqMeta?.alias || [],
    redirect: gender11tX6Gm8hqMeta?.redirect,
    component: () => import("/src/pages/funnel/profile/gender.vue").then(m => m.default || m)
  },
  {
    name: indexUgWisVug7yMeta?.name ?? "funnel-profile",
    path: indexUgWisVug7yMeta?.path ?? "/funnel/profile",
    meta: indexUgWisVug7yMeta || {},
    alias: indexUgWisVug7yMeta?.alias || [],
    redirect: indexUgWisVug7yMeta?.redirect,
    component: () => import("/src/pages/funnel/profile/index.vue").then(m => m.default || m)
  },
  {
    name: neuteredRiSTwbOsrcMeta?.name ?? "funnel-profile-neutered",
    path: neuteredRiSTwbOsrcMeta?.path ?? "/funnel/profile/neutered",
    meta: neuteredRiSTwbOsrcMeta || {},
    alias: neuteredRiSTwbOsrcMeta?.alias || [],
    redirect: neuteredRiSTwbOsrcMeta?.redirect,
    component: () => import("/src/pages/funnel/profile/neutered.vue").then(m => m.default || m)
  },
  {
    name: resumeMLYMFTGc0MMeta?.name ?? "funnel-resume",
    path: resumeMLYMFTGc0MMeta?.path ?? "/funnel/resume",
    meta: resumeMLYMFTGc0MMeta || {},
    alias: resumeMLYMFTGc0MMeta?.alias || [],
    redirect: resumeMLYMFTGc0MMeta?.redirect,
    component: () => import("/src/pages/funnel/resume.vue").then(m => m.default || m)
  },
  {
    name: activityjy5GQg4hAYMeta?.name ?? "funnel-weight-analysis-activity",
    path: activityjy5GQg4hAYMeta?.path ?? "/funnel/weight-analysis/activity",
    meta: activityjy5GQg4hAYMeta || {},
    alias: activityjy5GQg4hAYMeta?.alias || [],
    redirect: activityjy5GQg4hAYMeta?.redirect,
    component: () => import("/src/pages/funnel/weight-analysis/activity.vue").then(m => m.default || m)
  },
  {
    name: confirmHodc8OPOuzMeta?.name ?? "funnel-weight-analysis-fatness-confirm",
    path: confirmHodc8OPOuzMeta?.path ?? "/funnel/weight-analysis/fatness/confirm",
    meta: confirmHodc8OPOuzMeta || {},
    alias: confirmHodc8OPOuzMeta?.alias || [],
    redirect: confirmHodc8OPOuzMeta?.redirect,
    component: () => import("/src/pages/funnel/weight-analysis/fatness/confirm.vue").then(m => m.default || m)
  },
  {
    name: advicesKUPjKh2vl3Meta?.name ?? "funnel-weight-analysis-fatness-help-advices",
    path: advicesKUPjKh2vl3Meta?.path ?? "/funnel/weight-analysis/fatness/help/advices",
    meta: advicesKUPjKh2vl3Meta || {},
    alias: advicesKUPjKh2vl3Meta?.alias || [],
    redirect: advicesKUPjKh2vl3Meta?.redirect,
    component: () => import("/src/pages/funnel/weight-analysis/fatness/help/advices.vue").then(m => m.default || m)
  },
  {
    name: index7n66eYDDvWMeta?.name ?? "funnel-weight-analysis-fatness-help",
    path: index7n66eYDDvWMeta?.path ?? "/funnel/weight-analysis/fatness/help",
    meta: index7n66eYDDvWMeta || {},
    alias: index7n66eYDDvWMeta?.alias || [],
    redirect: index7n66eYDDvWMeta?.redirect,
    component: () => import("/src/pages/funnel/weight-analysis/fatness/help/index.vue").then(m => m.default || m)
  },
  {
    name: index8peen58gGcMeta?.name ?? "funnel-weight-analysis-fatness",
    path: index8peen58gGcMeta?.path ?? "/funnel/weight-analysis/fatness",
    meta: index8peen58gGcMeta || {},
    alias: index8peen58gGcMeta?.alias || [],
    redirect: index8peen58gGcMeta?.redirect,
    component: () => import("/src/pages/funnel/weight-analysis/fatness/index.vue").then(m => m.default || m)
  },
  {
    name: indexoCEH1DHZnuMeta?.name ?? "funnel-weight-analysis",
    path: indexoCEH1DHZnuMeta?.path ?? "/funnel/weight-analysis",
    meta: indexoCEH1DHZnuMeta || {},
    alias: indexoCEH1DHZnuMeta?.alias || [],
    redirect: indexoCEH1DHZnuMeta?.redirect,
    component: () => import("/src/pages/funnel/weight-analysis/index.vue").then(m => m.default || m)
  },
  {
    name: weightwHjWRXe8vjMeta?.name ?? "funnel-weight-analysis-weight",
    path: weightwHjWRXe8vjMeta?.path ?? "/funnel/weight-analysis/weight",
    meta: weightwHjWRXe8vjMeta || {},
    alias: weightwHjWRXe8vjMeta?.alias || [],
    redirect: weightwHjWRXe8vjMeta?.redirect,
    component: () => import("/src/pages/funnel/weight-analysis/weight.vue").then(m => m.default || m)
  },
  {
    name: youWXfuxlmy14Meta?.name ?? "funnel-you",
    path: youWXfuxlmy14Meta?.path ?? "/funnel/you",
    meta: youWXfuxlmy14Meta || {},
    alias: youWXfuxlmy14Meta?.alias || [],
    redirect: youWXfuxlmy14Meta?.redirect,
    component: () => import("/src/pages/funnel/you.vue").then(m => m.default || m)
  }
]